import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import type { Reducer, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { Toast } from 'js/src/components/common/Toast';
import { createReduxEnhancer } from 'js/src/libs/sentry';

let reduxDevTools = false;
///////////
/////////////////////
//////////

export function createReduxStore(rootReducer: Reducer, preloadedState: any = undefined) {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware({
                thunk: {
                    extraArgument: {},
                },
                immutableCheck: false,
                serializableCheck: false,
            });
        },
        devTools: reduxDevTools,
        enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(createReduxEnhancer()),
    });

///////////////
///////////////////////////////////
//////////////

    return store;
}

export function renderWithRedux(Component: React.ComponentType, store: Store) {
    const container = document.getElementById('react-root');
    if (!container) {
        throw new Error('Could not find #react-root element');
    }
    const root = createRoot(container);
    root.render(
        <Provider store={store}>
            <Component />
            <Toast.Component />
        </Provider>
    );
}

export function renderComponent(Component: React.ComponentType) {
    const container = document.getElementById('react-root');
    if (!container) {
        throw new Error('Could not find #react-root element');
    }
    const root = createRoot(container);
    root.render(
        <>
            <Component />
            <Toast.Component />
        </>
    );
}
