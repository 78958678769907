import 'css/src/components/common/ui/HiddenContainer';

import React from 'react';
import classNames from 'classnames';

type Props = {
    isHidden?: boolean,
    isHeightInherited?: boolean,
    children?: React.ReactNode,
    ['data-testid']?: string,
}
const HiddenContainer = ({ isHidden = false, isHeightInherited = true, children, 'data-testid': dataTestId }: Props) => {
    const classname = classNames({
        'inherit-height': isHeightInherited,
        'invisible': isHidden,
    }, 'hidden-container');
    return (
        <div className={classname} data-testid={dataTestId}>
            {children}
        </div>
    );
};

const MemoizedHiddenContainer = React.memo(HiddenContainer);
export default MemoizedHiddenContainer;
export { HiddenContainer };
